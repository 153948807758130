<template>
  <div class="flex justify-center w-full bg-brand-extra_light">
    <div class="flex flex-col items-center w-4/5 max-w-6xl my-16">
      <h1 class="text-4xl font-black text-center text-brand-darker">
        Any question ?
      </h1>
      <p class="text-lg mt-4 text-center text-brand-dark font-regular">
        Would you like to know more about the project and plans or how to use
        our widget and api ?
      </p>
      <div class="mt-10">
        <a
          href="mailto:gabrielluislopes00@gmail.com"
          class="px-16 py-4 mt-4 font-medium bg-brand-danger rounded-sm text-brand-extra_light focus:outline-none hover:bg-transparent border border-brand-danger hover:border-brand-darker hover:text-brand-darker transition duration-200 shadow-md"
        >
          Send an Email
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
