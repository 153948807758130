<template>
  <div class="flex justify-center py-10 bg-brand-light">
    <p class="font-medium text-center text-brand-darker text-lg">
      Feedback.<span class="text-brand-danger">i</span>o | Gabriel Lopes &copy;
      2021
    </p>
  </div>
</template>

<script>
export default {}
</script>
